import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { App } from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

console.log(String.raw`
  _____              __                                .___
_/ ____\____________/  |_  ____   ____  ___________  __| _/
\   __\/  _ \_  __ \   __\/ __ \_/ ___\/  _ \_  __ \/ __ |
 |  | (  <_> )  | \/|  | \  ___/\  \__(  <_> )  | \/ /_/ |
 |__|  \____/|__|   |__|  \___  >\___  >____/|__|  \____ |
                              \/     \/                 \/

     PathEditor for Robots. Powered by Web Technology.

`)

import React, { useContext } from "react";
import { css } from "emotion";
import { SettingsContext } from "./Editor";

function PointController({ index, position, weight, prec, disableRemove, dispatch }) {
  const onChangeX = (e) => {
    dispatch({
      type: "updatePointPosition",
      payload: {
        index,
        position: [ Number(e.target.value), position[1] ]
      }
    });
  };
  const onChangeY = (e) => {
    dispatch({
      type: "updatePointPosition",
      payload: {
        index,
        position: [ position[0], Number(e.target.value) ]
      }
    });
  };
  const onChangeW = (e) => {
    if ([ "", "0" ].includes(e.target.value)) return;
    dispatch({
      type: "updatePointWeight",
      payload: {
        index,
        weight: Number(e.target.value)
      }
    });
  };

  const onAddClick = (e) => {
    dispatch({ type: "insertPoint", payload: { index, position, weight } });
  };

  const onDeleteClick = (e) => {
    dispatch({ type: "deletePoint", payload: { index } })
  };

  const pointController = css`
    display: flex;
    padding: 10px;
    margin: 5px 0;
    border-radius: 2px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, .4), -1px -1px 2px rgba(200, 200, 200, .4);
  `;

  const pointButton = css`
    border: none;
    height: 20px;
    font-size: 15px;
    margin: 0 5px;
    padding: 0 5px;
    color: #007fff;
    background-color: #fff;
    cursor: pointer;
    transition-duration: .2s;

    :hover {
      filter: saturate(60%);
    }

    :disabled {
      cursor: default;
      color: inherit;
      opacity: 20%;
    }
  `;

  const pointIndex = css`
    width: 20px;
    text-align: center;
    color: #aaa;
    border-right: 1px solid #aaa;
    margin-right: 10px;
  `;

  const coordinateController = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  `;

  const coordinateInputs = css`
    display: flex;
    align-items: center;
  `;

  const coordinateInput = css`
    margin: 0 5px;

    label {
      display: block;
      font-size: 12px;
      line-height: 12px;
      color: #aaa;
    }

    input {
      display: block;
      width: 80px;
      height: 30px;
      box-sizing: border-box;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #ddd;
      font-size: 16px;
      padding: 0 2px;
    }
  `;

  prec = Math.pow(10, prec);
  const step = 1 / prec;
  return (
    <div className={ pointController }>
      <div className={ pointIndex }>{ index + 1 }</div>
      <div className={ coordinateController }>
        <div className={ coordinateInputs }>
          <div className={ coordinateInput }>
            <label>Position X</label>
            <input
              type="number"
              step={ step }
              value={ Math.round(position[0] * prec) / prec }
              onChange={ onChangeX } />
          </div>

          <div className={ coordinateInput }>
            <label>Position Y</label>
            <input
              type="number"
              step={ step }
              value={ Math.round(position[1] * prec) / prec }
              onChange={ onChangeY } />
          </div>

          <div className={ coordinateInput }>
            <label>Weight</label>
            <input
              type="number"
              step={ step }
              value={ Math.round(weight * prec) / prec }
              onChange={ onChangeW } />
          </div>
        </div>
        <div>
          <button
            className={ pointButton }
            onClick={ onAddClick }>
            Add
          </button>
          <button
            className={ pointButton }
            style={{ color: "#de0000" }}
            disabled={ disableRemove }
            onClick={ onDeleteClick }>
            Remove
          </button>
        </div>
      </div>
    </div>
  );
}

export function PointManager() {
  const [ state, dispatch ] = useContext(SettingsContext);

  return (
    <div>
      {
        state.points.positions.map((e, i) =>
          <PointController
            key={ i }
            index={ i }
            position={ e }
            weight={ state.points.weights[i] }
            prec={ state.displayPrecision }
            disableRemove={ state.points.positions.length <= 4 }
            dispatch={ dispatch } />)
      }
    </div>
  );
}

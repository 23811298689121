import React, { useReducer } from "react";
import { css } from "emotion";
import nurbs from "nurbs";
import { Plotter } from "./Plotter";
import { SettingManager } from "./SettingManager";
import { PointManager } from "./PointManager";
import { DegreeManager } from "./DegreeManager";
import { FileLoader } from "./FileLoader";
import { Sampler } from "./Sampler";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const initialPositions = [ [ 0, 0 ], [ 0.8, 0.2 ], [ 0.6, 0.9 ], [ 0.2, 0.4 ], [ 1, 1 ] ];
const initialWeights = [ 1, 1, 1, 1, 1 ];

const initialDegree = [
  { position: 0, degree: 0 },
  { position: 0.5, degree: 90 },
  { position: 1.3, degree: 180 },
  { position: initialPositions.length - 3, degree: -90 },
];

const [ initialCurve, initialDivideAt ] = subdivide([], [], initialPositions, initialWeights);

const initialState = {
  points: {
    positions: initialPositions,
    weights: initialWeights
  },
  displayPrecision: 3,
  shape: undefined,
  degrees: initialDegree,
  displayMarker: mapMarkerOnCurve([], initialPositions, initialWeights, initialDegree),
  curve: initialCurve,
  divideAt: initialDivideAt,
  sample: [],
  interval: 0.01,
  degreeUnit: "rad"
};

function subdivide(curveRes, atRes, positions, weights) {
  if (positions.length < 4) return [ curveRes, atRes ];

  const curve = nurbs({
    points: positions,
    weights: weights,
    degree: 3,
    boundary: "clamped"
  });
  const derivative = curve.evaluator(1);

  const deg = ([ a, b ], [ c, d ]) => {
    return Math.atan(Math.abs((a * d - b * c) / (a * c + b * d)));
  };

  const halve = (curveRes, atRes, l, r) => {
    const [ lx, ly ] = curve.evaluate([], l), [ rx, ry ] = curve.evaluate([], r);
    if (Math.hypot(rx - lx, ry - ly) < 1e-9) return;

    const m = (l + r) / 2;
    const vl = derivative([], l), vm = derivative([], m), vr = derivative([], r);
    const allow = Math.PI * (2 / 180);
    if (deg(vl, vm) < allow && deg(vm, vr) < allow && deg(vl, vr) < allow) {
      atRes.push(l);
      curveRes.push(curve.evaluate([], l));
      return;
    }

    halve(curveRes, atRes, l, m);
    halve(curveRes, atRes, m, r);
  }

  const [ begin, end ] = curve.domain[0];
  const division = curve.size - 3;
  for (let i = 0; i < division; i++) {
    const l = (end - begin) * i / division + begin;
    const r = (end - begin) * (i + 1) / division + begin;
    halve(curveRes, atRes, l, r);
  }
  atRes.push(end);
  curveRes.push(curve.evaluate([], end));
  return [ curveRes, atRes ];
}

function mapMarkerOnCurve(res, positions, weights, markers) {
  const curve = nurbs({
    points: positions,
    weights: weights,
    degree: 3,
    boundary: "clamped"
  });

  markers.forEach((e) => res.push({
    point: curve.evaluate([], e.position + 3),
    degree: e.degree
  }));

  return res;
}

function reducer(state, { type, payload }) {
  const positions = Array.from(state.points.positions);
  const weights = Array.from(state.points.weights);
  const degrees = state.degrees.map(e => ({ ...e }));

  let curve, divideAt;
  switch (type) {
    case "updateSettings":
      return payload.settings;


    case "insertPoint":
      positions.splice(payload.index, 0, payload.position);
      weights.splice(payload.index, 0, payload.weight);
      for (let i = 1; i < degrees.length; i++) {
        if (Math.max(0, payload.index - 2) <= degrees[i].position) degrees[i].position += 1;
      }

      [ curve, divideAt ] = subdivide([], [], positions, weights);
      return {
        ...state,
        points: {
          positions,
          weights,
        },
        degrees,
        curve,
        divideAt,
        displayMarker: mapMarkerOnCurve([], positions, weights, degrees),
        sample: [],
      };
    case "updatePointPosition":
      positions[payload.index] = typeof payload.position === "function"
        ? payload.position(positions[payload.index])
        : payload.position;

      [ curve, divideAt ] = subdivide([], [], positions, weights);
      return {
        ...state,
        points: {
          positions,
          weights,
        },
        curve,
        divideAt,
        displayMarker: mapMarkerOnCurve([], positions, weights, degrees),
        sample: [],
      };
    case "updatePointWeight":
      weights[payload.index] = typeof payload.weight === "function"
        ? payload.weight(weights[payload.index])
        : payload.weight;

      [ curve, divideAt ] = subdivide([], [], positions, weights);
      return {
        ...state,
        points: {
          positions,
          weights,
        },
        curve,
        divideAt,
        displayMarker: mapMarkerOnCurve([], positions, weights, degrees),
        sample: [],
      };
    case "deletePoint":
      positions.splice(payload.index, 1);
      weights.splice(payload.index, 1);
      for (let i = 1; i < degrees.length; i++) {
        if (Math.max(0, payload.index - 2) <= degrees[i].position) degrees[i].position -= 1;
      }

      [ curve, divideAt ] = subdivide([], [], positions, weights);
      return {
        ...state,
        points: {
          positions,
          weights,
        },
        degrees,
        curve,
        divideAt,
        displayMarker: mapMarkerOnCurve([], positions, weights, degrees),
        sample: [],
      };


    case "insertDegreeMarker":
      degrees.splice(payload.index, 0, payload.marker);
      return {
        ...state,
        degrees,
        displayMarker: mapMarkerOnCurve([], positions, weights, degrees)
      };
    case "updateDegreeMarker":
      degrees[payload.index] = typeof payload.marker === "function"
        ? payload.marker(degrees[payload.index])
        : payload.marker;
      return {
        ...state,
        degrees,
        displayMarker: mapMarkerOnCurve([], positions, weights, degrees)
      };
    case "deleteDegreeMarker":
      degrees.splice(payload.index, 1);
      return {
        ...state,
        degrees,
        displayMarker: mapMarkerOnCurve([], positions, weights, degrees)
      };


    case "updateShape":
      return {
        ...state,
        shape: {
          name: payload.name,
          data: payload.data
        }
      };
    case "updateInterval":
      return {
        ...state,
        interval: payload.interval
      };
    case "updateDegreeUnit":
      return {
        ...state,
        degreeUnit: payload.unit
      }
    case "sample":
      return {
        ...state,
        sample: payload.sample
      };


    default:
      console.error("Invalid dispatch");
  }
}

export const SettingsContext = React.createContext();

export function Editor() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const tab = css`
    display: flex;
    padding: 0;
    width: 100%;
    border-bottom: 2px solid #222;

    .react-tabs__tab {
      display: block;
      padding: 5px 10px;
      margin: 0;
      list-style: none;
      height: 30px;
      line-height: 30px;
      cursor: pointer;

      &--selected {
        background-color: #222;
        color: #fff;
      }
    }
  `;

  return (
    <SettingsContext.Provider value={[ state, dispatch ]}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Plotter style={{ flex: "none" }} />
        <div style={{ width: "450px", padding: "10px", flex: "none" }}>
          <SettingManager />
          <Tabs>
            <TabList className={ tab }>
              <Tab>Control Point</Tab>
              <Tab>Degree Marker</Tab>
              <Tab>Shape</Tab>
              <Tab>Sampler</Tab>
            </TabList>

            <TabPanel>
              <PointManager />
            </TabPanel>
            <TabPanel>
              <DegreeManager />
            </TabPanel>
            <TabPanel>
              <FileLoader />
            </TabPanel>
            <TabPanel>
              <Sampler />
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </SettingsContext.Provider>
  );
}

import React, { useContext } from "react";
import { css } from "emotion";
import Spline from "cubic-spline";
import { SettingsContext } from "./Editor";

function distance([ a, b ], [ c, d ]) {
  return Math.hypot(c - a, d - b);
}

function calcIntersection(h, k, r, a, b, c, d) {
  const A = (c - a) ** 2 + (d - b) ** 2;
  const B = (c - a) * (a - h) + (d - b) * (b - k);
  const C = (a - h) ** 2 + (b - k) ** 2 - r ** 2;
  const D = B ** 2 - A * C;

  if (D < 0) return [];

  const res = [];
  if (D === 0) {
    const t = -B / A;
    if (0 <= t && t <= 1) res.push(t);
  } else {
    const t1 = (-B - Math.sqrt(D)) / A;
    const t2 = (-B + Math.sqrt(D)) / A;
    if (0 <= t1 && t1 <= 1) res.push(t1);
    if (0 <= t2 && t2 <= 1) res.push(t2);
  }
  return res
}

function complementDegree(curve, degrees, unit, divideAt, displayMarker) {
  const markers = degrees.map((e) => ({ ...e }));
  markers.sort((a, b) => a.position - b.position);

  const ts = [], thetas = [];

  for (let markerIdx = 0, curveIdx = 0, distSum = 0;
    curveIdx + 1 < curve.length; curveIdx++) {

    while (markerIdx < markers.length
      && divideAt[curveIdx] <= markers[markerIdx].position + 3
      && markers[markerIdx].position + 3 <= divideAt[curveIdx + 1]) {

      if (markerIdx === 0
        || markers[markerIdx].position !== markers[markerIdx - 1].position) {

        ts.push(distSum + distance(curve[curveIdx], displayMarker[markerIdx].point));
        thetas.push(
          unit === "deg"
          ? markers[markerIdx].degree
          : markers[markerIdx].degree / 180 * Math.PI);
      }

      markerIdx++;
    }

    distSum += distance(curve[curveIdx], curve[curveIdx + 1]);
  }

  return new Spline(ts, thetas);
}

function samplePath(curve, degreeEvaluator, interval, limit) {
  if (curve.length === 0) return [];

  const res = [ [ ...curve[0], degreeEvaluator.at(0) ] ];
  for (let i = 0, distSum = 0; i + 1 < curve.length; i++) {
    let [ h, k ] = res[res.length - 1];
    let [ a, b ] = curve[i], [ c, d ] = curve[i + 1];

    while (true) {
      if (res.length >= limit) return null;
      const [ t ] = calcIntersection(h, k, interval, a, b, c, d);
      if (t === undefined) break;
      const x = (1 - t) * a + t * c;
      const y = (1 - t) * b + t * d;
      [ h, k ] = [ a, b ] = [ x, y ];
      const theta = degreeEvaluator.at(distSum + distance(curve[i], [ x, y ]));
      res.push([ x, y, theta ]);
    }

    distSum += distance(curve[i], curve[i + 1]);
  }

  return res;
}

function toCSV(path) {
  return "x_path,y_path,theta_path\n"
  + path.map((e) => e.join()).join("\n")
}

export function Sampler() {
  const [ state, dispatch ] = useContext(SettingsContext);
  const {
    curve,
    degrees,
    divideAt,
    displayMarker,
    interval,
    degreeUnit,
    sample
  } = state;
  const markers = degrees.map((e) => ({ ...e }));
  markers.sort((a, b) => a.position - b.position);

  const limit = 5000;

  const onClickSampling = () => {

    const interval = document.getElementById("sampling-interval").value;
    if (interval <= 0) {
      alert("離散化間隔は0よりも大きい必要があります。");
      return;
    }

    const degreeEvaluator = complementDegree(curve, degrees, degreeUnit, divideAt, displayMarker);
    const samplingPoints = samplePath(curve, degreeEvaluator, interval, limit);
    if (samplingPoints === null) {
      alert(
        `離散化した点群の数が${limit}個を超えました。\n`
        + "経路長に対して離散化間隔が短すぎるようです。\n"
        + "処理を中断しました。");
      dispatch({ type: "sample", payload: { sample: [] } });
    } else {
      dispatch({ type: "sample", payload: { sample: samplingPoints } });
    }
  }

  const onClickDownload = () => {
    const blob = new Blob([ toCSV(sample) ], { type: "text/csv" });
    const downloader = document.createElement("a");
    downloader.href = URL.createObjectURL(blob);
    downloader.download = `fortecord-path-${new Date().toISOString()}.csv`;
    downloader.click();
  }

  const onChangeInterval = (e) => {
    dispatch({ type: "updateInterval", payload: { interval: e.target.value } })
  }

  const onChangeUnit = (e) => {
    dispatch({ type: "updateDegreeUnit", payload: { unit: e.target.value } });

    if (sample.length === 0) return;
    const degreeEvaluator = complementDegree(curve, degrees, e.target.value, divideAt, displayMarker);
    const samplingPoints = samplePath(curve, degreeEvaluator, interval, limit);
    if (samplingPoints === null) {
      dispatch({ type: "sample", payload: { sample: [] } });
    } else {
      dispatch({ type: "sample", payload: { sample: samplingPoints } });
    }
  }

  const samplerContent = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 2px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, .4), -1px -1px 2px rgba(200, 200, 200, .4);
  `;

  const intervalInput = css`
    margin: 0 5px;

    label {
      display: block;
      font-size: 12px;
      line-height: 12px;
      color: #aaa;
    }

    input {
      display: block;
      width: 70px;
      height: 30px;
      box-sizing: border-box;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #ddd;
      font-size: 16px;
      padding: 0 2px;
    }
  `;

  const unitInput = css`
    margin: 0 5px;

    label {
      display: block;
      font-size: 12px;
      line-height: 12px;
      color: #aaa;
    }

    div {
      display: flex;
      align-items: center;
      height: 30px;
    }

    span {
      display: inline-block;
      margin-right: 10px;
    }
  `;

  const samplerButton = css`
    border: none;
    height: 20px;
    font-size: 15px;
    margin: 0 5px;
    padding: 0 5px;
    color: #007fff;
    background-color: #fff;
    cursor: pointer;
    transition-duration: .2s;

    :hover {
      filter: saturate(60%);
    }
  `;

  const resultDisplay = css`
    width: 100%;
    height: 500px;
    overflow-y: scroll;
  `;

  return (
    <div>
      <div className={samplerContent}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className={ intervalInput }>
            <label>Interval</label>
            <input
              type="number"
              id="sampling-interval"
              onChange={onChangeInterval}
              defaultValue={interval}/>
          </div>
          <div className={ unitInput }>
            <label>Degree Unit</label>
            <div>
              <input
                type="radio"
                name="unit"
                value="rad"
                checked={ degreeUnit === "rad" }
                onChange={ onChangeUnit } />
                <span>rad</span>
              <input
                type="radio"
                name="unit"
                value="deg"
                checked={ degreeUnit === "deg" }
                onChange={ onChangeUnit } />
                <span>deg</span>
            </div>
          </div>
        </div>
        <div>
          <button
            className={samplerButton}
            onClick={onClickSampling}>
            Sample
          </button>
          <button
            className={samplerButton}
            onClick={onClickDownload}>
            Download
          </button>
        </div>
      </div>
      <pre className={samplerContent}>
        <code className={ resultDisplay }>
          { toCSV(state.sample) }
        </code>
      </pre>
    </div>
  );
}

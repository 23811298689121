import React, { useContext, useEffect, useState, useRef } from "react";
import { SettingsContext } from "./Editor";
import * as d3 from "d3";

export const PlotterContext = React.createContext();

function AxisLayer() {
  const { display, xScaler, yScaler } = useContext(PlotterContext);
  const layerRef = useRef(null);

  useEffect(() => {
    const layer = d3.select(layerRef.current);
    const { width, height } = display;

    layer.selectAll(".axis-x")
    .data([null])
    .join("g")
    .attr("class", "axis-x")
    .attr("transform", `translate(0, ${height})`)
    .call(d3.axisTop(xScaler)
      .tickSize(height))
    .call(g => g.selectAll(".domain")
      .remove())
    .call(g => g.selectAll(".tick line")
      .attr("stroke-width", 1)
      .attr("stroke-opacity", 0.2))
    .call(g => g.selectAll(".tick text")
      .attr("y", "-10px")
      .attr("dx", "15px"));

    layer.selectAll(".axis-y")
    .data([null])
    .join("g")
    .attr("class", "axis-y")
    .attr("transform", `translate(0, 0)`)
    .call(d3.axisRight(yScaler)
      .tickSize(width))
    .call(g => g.selectAll(".domain")
      .remove())
    .call(g => g.selectAll(".tick line")
      .attr("stroke-width", 1)
      .attr("stroke-opacity", 0.2))
    .call(g => g.selectAll(".tick text")
      .attr("x", "10px")
      .attr("dy", "-5px"));
  }, [ display, xScaler, yScaler ]);

  return <g ref={layerRef} />
}

function ShapeLayer() {
  const [ state ] = useContext(SettingsContext);
  const { xScaler, yScaler } = useContext(PlotterContext);
  const layerRef = useRef(null);

  useEffect(() => {
    if (!state.shape) return;

    const layer = d3.select(layerRef.current);
    layer.selectAll(".shape-line")
      .data(state.shape.data)
      .join("line")
      .attr("class", "shape-line")
      .attr("x1", (d) => xScaler(d[0]))
      .attr("y1", (d) => yScaler(d[1]))
      .attr("x2", (d) => xScaler(d[2]))
      .attr("y2", (d) => yScaler(d[3]))
      .attr("stroke", "#f00")
      .attr("stroke-width", "2px");
  }, [ state, xScaler, yScaler ]);

  return <g ref={layerRef} />
}

function CurveLayer() {
  const [ state ] = useContext(SettingsContext);
  const { xScaler, yScaler } = useContext(PlotterContext);
  const layerRef = useRef(null);

  useEffect(() => {
    const layer = d3.select(layerRef.current);
    const curveLine = d3.line()
      .x((d) => xScaler(d[0]))
      .y((d) => yScaler(d[1]));

    layer.selectAll(".curve-points")
      .data([state.curve])
      .join("path")
      .attr("class", "curve-points")
      .attr("d", curveLine)
      .attr("stroke", "#00f")
      .attr("fill", "none")
      .attr("stroke-width", "3px");
  }, [ state, xScaler, yScaler ]);

  return <g ref={layerRef} />
}

function MarkerLayer() {
  const [ state ] = useContext(SettingsContext);
  const { xScaler, yScaler } = useContext(PlotterContext);
  const layerRef = useRef(null);

  useEffect(() => {
    const layer = d3.select(layerRef.current);
    const line = d3.line()
      .x((d) => xScaler(d[0]))
      .y((d) => yScaler(d[1]));

    layer.selectAll(".control-lines")
      .data([state.points.positions])
      .join("path")
      .attr("class", "control-lines")
      .attr("d", line)
      .attr("stroke", "#f00")
      .attr("fill", "none")
      .attr("stroke-width", "2px");

    layer.selectAll(".marker")
      .data(state.displayMarker)
      .join("path")
      .attr("d", "M0 -12 L-6 12 L0 6 L6 12 Z")
      .attr("class", "marker")
      .attr("transform", (d) => `
        translate(${xScaler(d.point[0])}, ${yScaler(d.point[1])})
        rotate(${90 - d.degree})
      `)
      .attr("stroke-width", "2px")
      .attr("stroke", "orange")
      .attr("fill", "white")
  }, [ state, xScaler, yScaler ]);

  return <g ref={layerRef} />
}

function SamplingPointLayer() {
  const [ state ] = useContext(SettingsContext);
  const { xScaler, yScaler } = useContext(PlotterContext);
  const layerRef = useRef(null);

  useEffect(() => {
    if (state.sample === undefined) return;
    const layer = d3.select(layerRef.current);

    const samplingLine = d3.line()
      .x((d) => xScaler(d[0]))
      .y((d) => yScaler(d[1]));
    layer.selectAll(".sampling-line")
      .data([state.sample])
      .join("path")
      .attr("class", "sampling-line")
      .attr("d", samplingLine)
      .attr("stroke", "yellowgreen")
      .attr("fill", "none")
      .attr("stroke-width", "3px");

    layer.selectAll(".sampling-point")
      .data(state.sample)
      .join("path")
      .attr("d", "M0 -10 L-5 10 L0 5 L5 10 Z")
      .attr("class", "sampling-point")
      .attr("transform", (d) => `
        translate(${xScaler(d[0])}, ${yScaler(d[1])})
        rotate(${
          state.degreeUnit === "deg"
          ? 90 - d[2]
          : 90 - d[2] / Math.PI * 180
        })
      `)
      .attr("stroke-width", "2px")
      .attr("stroke", "yellowgreen")
      .attr("fill", "white")
  }, [ state, xScaler, yScaler ]);

  return <g ref={layerRef} />
}

function CurveControlLayer() {
  const [ state, dispatch ] = useContext(SettingsContext);
  const { xScaler, yScaler } = useContext(PlotterContext);
  const layerRef = useRef(null);
  const xScalerRef = useRef(null);
  const yScalerRef = useRef(null);
  xScalerRef.current = xScaler;
  yScalerRef.current = yScaler;

  useEffect(() => {
    const layer = d3.select(layerRef.current);
    const drag = d3.drag()
      .on("drag", (_, i) => {
        const xScaler = xScalerRef.current;
        const yScaler = yScalerRef.current;
        dispatch({
          type: "updatePointPosition",
          payload: {
            index: i,
            position: (pre) => ([
              xScaler.invert(xScaler(pre[0]) + d3.event.dx),
              yScaler.invert(yScaler(pre[1]) + d3.event.dy),
            ])
          }
        });
      }
    );

    const wheel = (_, i) => {
      dispatch({
        type: "updatePointWeight",
        payload: {
          index: i,
          weight: (pre) => d3.event.deltaY > 0 ? pre / 1.2 : pre * 1.2
        }
      });
    };

    layer.selectAll(".control-point")
      .data(state.points.positions)
      .join((enter) =>
        enter.append("circle")
          .call(drag)
          .on("wheel", wheel))
      .attr("class", "control-point")
      .attr("cx", (d) => xScaler(d[0]))
      .attr("cy", (d) => yScaler(d[1]))
      .attr("stroke", "green")
      .attr("stroke-width", 3)
      .attr("r", 7)
      .attr("fill", "#fff")
      .attr("style", "cursor: move");
  }, [ state, dispatch, xScaler, yScaler ]);

  return <g ref={layerRef} />
}

function DisplayControlLayer() {
  const { display, setDisplay, xScaler, yScaler } = useContext(PlotterContext);
  const layerRef = useRef(null);
  const xScalerRef = useRef(null);
  const yScalerRef = useRef(null);
  xScalerRef.current = xScaler;
  yScalerRef.current = yScaler;

  useEffect(() => {
    const layer = d3.select(layerRef.current);

    const drag = d3.drag()
      .on("start", (_, i, e) => {
        d3.select(e[i]).attr("style", "cursor: grab");
      })
      .on("end", (_, i, e) => {
        d3.select(e[i]).attr("style", "cursor: default");
      })
      .on("drag", _ => {
        setDisplay(display => {
          const [ offsetX, offsetY ] = display.offset;
          const { dx, dy } = d3.event;
          const xScaler = xScalerRef.current;
          const yScaler = yScalerRef.current;
          const nOffsetX = xScaler.invert(xScaler(offsetX) - dx);
          const nOffsetY = yScaler.invert(yScaler(offsetY) - dy);
          return { ...display, offset: [ nOffsetX, nOffsetY ] };
        })
      });

    const wheel = (_, i, e) => {
      d3.event.preventDefault();
      setDisplay(display => {
        const newScale = d3.event.deltaY > 0 ? display.scale / 1.2 : display.scale * 1.2;
        const [ mouseX, mouseY ] = d3.mouse(e[i]);
        const [ offsetX, offsetY ] = display.offset;
        const xScaler = xScalerRef.current;
        const yScaler = yScalerRef.current;
        const focusX = xScaler.invert(mouseX);
        const focusY = yScaler.invert(mouseY);
        const focusOffsetX = (focusX - offsetX) * display.scale / newScale;
        const focusOffsetY = (focusY - offsetY) * display.scale / newScale;
        const nOffsetX = focusX - focusOffsetX;
        const nOffsetY = focusY - focusOffsetY;
        return { ...display, offset: [ nOffsetX, nOffsetY ], scale: newScale };
      })
    };

    layer.selectAll(".display-control-rect")
      .data([null])
      .enter()
      .append("rect")
      .attr("class", "display-control-rect")
      .attr("width", display.width)
      .attr("height", display.height)
      .attr("opacity", 0)
      .call(drag)
      .on("wheel", wheel);
  }, [ display, setDisplay, xScaler, yScaler ]);

  return <g ref={layerRef} />
}

export function Plotter() {
  const [ display, setDisplay ] = useState({
    offset: [ 0, 0 ],
    scale: 1,
    width: 800,
    height: 800
  });

  const displayW = 1, displayH = 1;
  const { width, height, offset } = display;
  const [ offsetX, offsetY ] = offset;
  const xScaler = d3.scaleLinear()
    .domain([ offsetX, offsetX + displayW / display.scale ])
    .range([ 0, width ]);
  const yScaler = d3.scaleLinear()
    .domain([ offsetY, offsetY + displayH / display.scale ])
    .range([ height, 0 ]);

  return (
    <PlotterContext.Provider value={{ display, setDisplay, xScaler, yScaler }}>
      <svg width={width} height={height}>
        <AxisLayer />
        <ShapeLayer />
        <CurveLayer />
        <MarkerLayer />
        <SamplingPointLayer />
        <DisplayControlLayer />
        <CurveControlLayer />
      </svg>
    </PlotterContext.Provider>
  );
}

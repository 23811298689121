import React, { useContext } from "react";
import { css } from "emotion";
import { SettingsContext } from "./Editor";

export function SettingManager() {
  const [ state, dispatch ] = useContext(SettingsContext);

  const onClickImport = () => {
    alert("警告：設定の入出力は実験的に実装されています。\n互換性が保証されていないことを理解した上で使って下さい。");
  }

  const onChangeImport = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      dispatch({ type: "updateSettings", payload: { settings: JSON.parse(reader.result) } });
    };
    reader.readAsText(file);
  };

  const onClickExport = () => {
    alert("警告：設定の入出力は実験的に実装されています。\n互換性が保証されていないことを理解した上で使って下さい。");
    const blob = new Blob([ JSON.stringify(state, null, "    ") ], { type: "application/json" });
    const exporter = document.createElement("a");
    exporter.href = URL.createObjectURL(blob);
    exporter.download = `fortecord-settings-${new Date().toISOString()}.json`;
    exporter.click();
  };

  const settingIO = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 2px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, .4), -1px -1px 2px rgba(200, 200, 200, .4);
  `;

  const settingIOButton = css`
    border: none;
    height: 20px;
    font-size: 15px;
    margin: 0 5px;
    padding: 0 5px;
    color: #007fff;
    background-color: #fff;
    cursor: pointer;
    transition-duration: .2s;

    :hover {
      filter: saturate(60%);
    }
  `;

  return (
    <div className={settingIO}>
      <div>Settings</div>
      <div>
        <label
          htmlFor="import-settings"
          onClick={onClickImport}
          className={settingIOButton}>
          Import
        </label>
        <input
          type="file"
          id="import-settings"
          onChange={onChangeImport}
          style={{ display: "none" }} />
        <button
          onClick={onClickExport}
          className={settingIOButton}>
          Export
        </button>
      </div>
    </div>
  );
}

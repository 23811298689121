import React, { useContext } from "react";
import { css } from "emotion";
import { SettingsContext } from "./Editor";

export function DegreeMarker({ index, marker, prec, disableChange, dispatch }) {
  const degreeMarker = css`
    display: flex;
    padding: 10px;
    margin: 5px 0;
    border-radius: 2px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, .4), -1px -1px 2px rgba(200, 200, 200, .4);
  `;

  const degreeMarkerIndex = css`
    width: 20px;
    text-align: center;
    color: #aaa;
    border-right: 1px solid #aaa;
    margin-right: 10px;
  `;

  const markerButton = css`
    border: none;
    height: 20px;
    font-size: 15px;
    margin: 0 5px;
    padding: 0 5px;
    color: #007fff;
    background-color: #fff;
    cursor: pointer;
    transition-duration: .2s;

    :hover {
      filter: saturate(60%);
    }

    :disabled {
      cursor: default;
      color: inherit;
      opacity: 20%;
    }
  `;

  const degreeController = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  `;

  const degreeInput = css`
    margin: 0 5px;

    label {
      display: block;
      font-size: 12px;
      line-height: 12px;
      color: #aaa;
    }

    input {
      display: block;
      width: 80px;
      height: 30px;
      box-sizing: border-box;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #ddd;
      font-size: 16px;
      padding: 0 2px;

      :disabled {
        color: #aaa;
        background-color: #f6f6f6;
      }
    }
  `;

  const degreeInputs = css`
    display: flex;
    align-items: center;
  `;

  const onChangePosition = (e) => {
    dispatch({
      type: "updateDegreeMarker",
      payload: {
        index,
        marker: {
          ...marker,
          position: Number(e.target.value)
        }
      }
    });
  };

  const onChangeDegree = (e) => {
    dispatch({
      type: "updateDegreeMarker",
      payload: {
        index,
        marker: {
          ...marker,
          degree: Number(e.target.value)
        }
      }
    });
  }

  const onAddClick = (e) => {
    dispatch({ type: "insertDegreeMarker", payload: { index, marker } });
  };

  const onDeleteClick = (e) => {
    dispatch({ type: "deleteDegreeMarker", payload: { index } })
  };

  return (
    <div className={ degreeMarker }>
      <div className={ degreeMarkerIndex }>{index + 1}</div>
      <div className={ degreeController }>
        <div className={ degreeInputs }>
          <div className={ degreeInput }>
            <label>Position</label>
            <input
              type="number"
              step={ 1 / Math.pow(10, prec) }
              value={ marker.position }
              disabled={ disableChange }
              onChange={ onChangePosition } />
          </div>
          <div className={ degreeInput }>
            <label>Degree</label>
            <input
              type="number"
              step={ 0.1 }
              value={ marker.degree }
              onChange={ onChangeDegree } />
          </div>
        </div>
        <div>
          <button
            className={ markerButton }
            onClick={ onAddClick }>
            Add
          </button>
          <button
            className={ markerButton }
            style={{ color: "#de0000" }}
            disabled={ disableChange }
            onClick={ onDeleteClick }>
            Remove
          </button>
        </div>
      </div>
    </div>
  );
}

export function DegreeManager() {
  const [ state, dispatch ] = useContext(SettingsContext);

  return (
    <div>
      {
        state.degrees.map((e, i) =>
          <DegreeMarker
            key={i}
            index={i}
            marker={e}
            prec={state.displayPrecision}
            disableChange={i === 0 || i === state.degrees.length - 1}
            dispatch={dispatch} />)
      }
    </div>
  );
}

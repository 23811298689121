import React, { useContext } from "react";
import { css } from "emotion";
import { SettingsContext } from "./Editor";

export function FileLoader() {
  const [ state, dispatch ] = useContext(SettingsContext);

  const onChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      let data = [];
      if (file.name.endsWith(".csv")) {
        data = reader.result.split("\n").map(e => e.split(",").map(f => Number(f)));
        console.log(data)
      }
      if (file.name.endsWith(".json")) {
        data = JSON.parse(reader.result);
      }
      dispatch({
        type: "updateShape",
        payload: {
          name: file.name,
          data
        }
      });
    };
    reader.readAsText(file);
  }

  const fileLoader = css`
    padding: 10px;
  `;

  const fileSelector = css`
    border: none;
    height: 20px;
    font-size: 15px;
    margin: 0 5px;
    padding: 0 5px;
    color: #007fff;
    background-color: #fff;
    cursor: pointer;
    transition-duration: .2s;

    :hover {
      filter: saturate(60%);
    }
  `;

  const shapeController = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 2px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, .4), -1px -1px 2px rgba(200, 200, 200, .4);
  `;

  return (
    <div className={shapeController}>
      <div style={{ padding: "10px" }}>{ state.shape ? state.shape.name : "No shape is loaded." }</div>
      <div className={fileLoader}>
        <label className={fileSelector} htmlFor="file-selector">Load</label>
        <input id="file-selector" style={{ display: "none" }} type="file" onChange={onChange} />
      </div>
    </div>
  );
}

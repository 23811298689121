import React from 'react';
import { Editor } from './components/Editor';

export function App() {
  return (
    <div className="App">
      <Editor></Editor>
    </div>
  );
}
